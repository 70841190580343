// Core
import React from "react";
import styles from "./styles.module.css";
// Components
import Layout from "../layout/";
import { RegionCard } from "../components/";

const Region = () => {
  return (
    <Layout>
      <div className={styles.root}>
        <div className={styles.inner}>
          <header>
            <h1>Select your region</h1>
            <p>The InMode Resources Center has moved to a new platform. To get started, please pick your region below.</p>
          </header>

          <ul>
            <RegionCard
              image="/images/au.svg"
              title="Australia"
              url="https://au.inmoderesources.com"
            />

            <RegionCard
              image="/images/ca.svg"
              title="Canada"
              url="https://ca.inmoderesources.com"
            />

            <RegionCard
              image="/images/fr.svg"
              title="France"
              url="https://fr.inmoderesources.com"
            />

            <RegionCard
              image="/images/de.svg"
              title="Germany"
              url="https://de.inmoderesources.com"
            />

            <RegionCard
              image="/images/it.svg"
              title="Italy"
              url="https://it.inmoderesources.com"
            />

            <RegionCard
              image="/images/es.svg"
              title="Spain"
              url="https://es.inmoderesources.com"
            />

            <RegionCard
              image="/images/gb.svg"
              title="UK"
              url="https://gb.inmoderesources.com"
            />

            <RegionCard
              image="/images/us.svg"
              title="USA"
              url="https://us.inmoderesources.com"
            />

            <RegionCard
              image="/images/intl.png"
              title="International"
              url="https://intl.inmoderesources.com"
            />
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default Region;
